import React from "react";

import { useHowWeWorkContent } from "../../../hooks/useHowWeWork/useHowWeWorkContent";
import "./HowWeWorkHeaderSection.scss";

const HowWeWorkContent = () => {
  let mycontent = useHowWeWorkContent();
  // console.log(mycontent.content);

  const how_we_work_style = `
  <style>
  .ourStepProcessSection ul{
    margin: 10px 15rem;
  }

  .ourStepProcessSection h1 {
     color:black;
     font-size: 7rem;
     line-height: 7rem;
     margin: 10px 15rem;
    }
   .ourStepProcessSection p.aligncenter{
     text-align:center;
   }

   .ourStepProcessSection p.alignleft{
    text-align:left;
  }
  .ourStepProcessSection p.alignright{
    text-align:right;
  }


    .ourStepProcessSection h2 {
      color:black;
      font-size: 5rem;
      line-height: 5rem;
      margin: 10px 15rem;
     }

     .ourStepProcessSection h3 {
      color:black;
      font-size: 3.6rem;
      line-height: 3.6rem;
      margin: 10px 15rem;
     }

     .ourStepProcessSection h4 {
      color:black;
      font-size: 3rem;
      line-height: 3rem;
      margin: 10px 15rem;
     }

     p.fullwidth {
      margin:0 !important;
     }
     p.aligncenter {
      text-align:center;
     }

  </style>
  `;

  mycontent.content = how_we_work_style; // + mycontent;


  return (mycontent)  ? (
      <div className="ourStepProcessSection m-4">

        <div className="container" style={{ margin : "0 20rem" }}>
        
          <div
            dangerouslySetInnerHTML={{
              __html: mycontent.content,
            }}
            
          />
         
          </div>
      </div>

  ): null;


};

export default HowWeWorkContent;
