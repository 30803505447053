import React from "react";
import Jumbotron from "../../Jumbotron/Jumbotron";
import Spacer from "../../Spacer/Spacer";
import { useHowWeWorkHeadingSection } from "../../../hooks/useHowWeWork/useHowWeWorkHeadingSection";
import { useHowWeWorkTopContent } from "../../../hooks/useHowWeWork/useHowWeWorkTopContent";
import "./HowWeWorkHeaderSection.scss";

const HowWeWorkHeaderSection = () => {
  const { heading, backgroundImage } = useHowWeWorkHeadingSection();
  const { copy, showSection } = useHowWeWorkTopContent();

  return (
    <div className="howWeWorkHeaderSection">
      <Jumbotron
        media={backgroundImage}
        title={heading || "We don't have a title for the moment"}
      />
      {showSection && (
        <div className="container">
          <Spacer mobileSize={30} size={70} />
          <div
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: copy,
            }}
            className="content-wrapper"
          />
          {/* <Spacer mobileSize={10} size={50} />
          <hr size="3" className="divider grey" /> */}
        </div>
      )}
    </div>
  );
};

export default HowWeWorkHeaderSection;
