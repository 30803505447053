import React from "react";
import { useHowWeWorkFeaturedPages } from "../../../hooks/useHowWeWork/useHowWeWorkFeaturedPages";
import FeaturedPages from "../../FeaturedPages/FeaturedPages";

const FeaturedPagesSection = () => {
  const { featuredPage1, featuredPage2, showSection } =
    useHowWeWorkFeaturedPages();

  return (
    <FeaturedPages
      featuredPage1={featuredPage1}
      featuredPage2={featuredPage2}
      showSection={showSection}
    />
  );
};

export default FeaturedPagesSection;
