import { useStaticQuery, graphql } from "gatsby";
export const useHowWeWorkHeadingSection = () => {
  const data = useStaticQuery(graphql`
    {
      wpPage(template: { templateName: { eq: "Page How We Work" } }) {
        howWeWork {
          howWeWorkHeadingSection {
            heading
            backgroundImage {
              localFile {
                childImageSharp {
                  gatsbyImageData(formats: WEBP)
                }
              }
              altText
            }
          }
        }
      }
    }
  `);
  return data.wpPage.howWeWork.howWeWorkHeadingSection;
};
