import React from "react";
import { uniqueId } from "lodash";
import IsrLabel from "../../IsrLabel/IsrLabel";
import Spacer from "../../Spacer/Spacer";
import { useHowWeWorkOurProcesses } from "../../../hooks/useHowWeWork/useHowWeWorkOurProcesses";
import "./OurStepProcessSection.scss";

const OurStepProcessSection = () => {
  const { heading, copy, howWeWorkProcess } = useHowWeWorkOurProcesses();

  return (
    <div className="ourStepProcessSection">
      <div className="container">
        <Spacer mobileSize={10} size={20} />
        {heading && <h2>{heading}</h2>}
        {copy && (
          <>
            <Spacer mobileSize={10} size={10} />
            <div
              className="content-wrapper"
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{
                __html: copy,
              }}
            />
          </>
        )}
        <Spacer mobileSize={30} size={50} />
        {howWeWorkProcess &&
          howWeWorkProcess.map((process, i) => {
            const {
              howWeWorkProcessHeading,
              howWeWorkProcessContent,
              showSection,
            } = process;

            if (showSection)
              return (
                <div key={uniqueId("isr_process")}>
                  <div className="processes">
                    <IsrLabel variant="primary" size="xl" color="blue">
                      {i + 1}
                    </IsrLabel>
                    {howWeWorkProcessHeading && (
                      <h2>{howWeWorkProcessHeading}</h2>
                    )}
                    {howWeWorkProcessContent && (
                      <div
                        // eslint-disable-next-line react/no-danger
                        dangerouslySetInnerHTML={{
                          __html: howWeWorkProcessContent,
                        }}
                      />
                    )}
                  </div>
                  <Spacer mobileSize={30} size={70} />
                </div>
              );
            return null;
          })}

        <hr size="3" className="divider grey" />
      </div>
    </div>
  );
};

export default OurStepProcessSection;
