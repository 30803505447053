import { useStaticQuery, graphql } from "gatsby";

export const useHowWeWorkTopContent = () => {
  const data = useStaticQuery(graphql`
    {
      wpPage(template: { templateName: { eq: "Page How We Work" } }) {
        howWeWork {
          howWeWorkTopContent {
            copy
            showSection
          }
        }
      }
    }
  `);
  return data.wpPage.howWeWork.howWeWorkTopContent;
};
