import { useStaticQuery, graphql } from "gatsby";

export const useHowWeWorkContent = () => {
  const data = useStaticQuery(graphql`
    {
      wpPage(id: {eq: "cG9zdDo1Mjg="}) {
        id
        content
      }
    }
  `);

  console.log(data.wpPage.content);


  return data.wpPage;
};
