import React from "react";
import Layout from "../components/layout/layout";
import Header from "../components/Header/Header";
import HowWeWorkHeaderSection from "../components/HowWeWork/HowWeWorkHeaderSection/HowWeWorkHeaderSection";
import HowWeWorkContent from "../components/HowWeWork/HowWeWorkHeaderSection/HowWeWorkContent";

import OurStepProcessSection from "../components/HowWeWork/OurStepProcessSection/OurStepProcessSection";
import IsrInActionSection from "../components/HowWeWork/IsrInActionSection/IsrInActionSection";
import Footer from "../components/Footer/Footer";
import FeaturedPagesSection from "../components/HowWeWork/FeaturedPagesSection/FeaturedPagesSection";

const HowWeWork = () => {
  return (
    <Layout>
      <Header />
      <HowWeWorkHeaderSection />
      <HowWeWorkContent />
      <IsrInActionSection />
      <OurStepProcessSection />
      <FeaturedPagesSection />
      <Footer />
    </Layout>
  );
};

export default HowWeWork;
