import React from "react";
import { useHowWeWorkBottomContent } from "../../../hooks/useHowWeWork/useHowWeWorkBottomContent";
import IsrVideo from "../../IsrVideo/IsrVideo";
import Spacer from "../../Spacer/Spacer";
import "./IsrInActionSection.scss";

const IsrInActionSection = () => {
  const {
    heading,
    copy,
    videoUrl,
    showSection: showVideo,
  } = useHowWeWorkBottomContent();

  return (
    <div className="isrInActionSection">
      {showVideo && (
        <div className="container">
          <div className="isrInActionHeader">
            <Spacer size={50} mobileSize={10} />
            {heading && (
              <>
                <h2>{heading}</h2>
                <Spacer size={20} mobileSize={10} />
              </>
            )}
            {copy && (
              <div
                className="content-wrapper"
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{ __html: copy }}
              />
            )}
          </div>
        </div>
      )}

      {videoUrl && showVideo && (
        <IsrVideo videoUrl={videoUrl} removeBottomMargin />
      )}
      <div className="container">
        <div className="row">
          <div className="col-md-2" />
          <div className="col-md-8">
            <Spacer mobileSize={10} size={20} />
            <hr size="3" className="divider grey" />
          </div>
          <div className="col-md-2" />
        </div>
      </div>
    </div>
  );
};

export default IsrInActionSection;
